import React from 'react';

const NotFoundPage = () => {
    return (
        <main className='NotFoundPageWrap p-5'>
            <img src="/img/http-error-404-not-found.png" alt="" className='img-fluid' />
        </main>
    );
}

export default NotFoundPage;
