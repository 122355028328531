import React from 'react'

export default function Cancellation_Policy() {
    return (
        <div className='tc_cms_pages_wrapper  py-5 animate__animated animate__fadeIn'>

            <div className="container">

                <div className='main'>
                    <h1><span>Cancellation Policy</span></h1>
                    <p>Every reservation made on navchr online is subject to cancellation charges levied by the airline, which may vary by flight and booking class.</p>
                    <p>Some booked fares may be non-refundable as per the specific airline's rules.</p>
                    <p>Convenience fee is non-refundable in case of ticket cancellation by the all users, or flight cancellation by the airline.</p>
                    <p>Cancellation charge may be applying any flight ticket cancel (Rs 100.00 + 18%) (One hundred eighteen rupees only) including GST per passenger & Transition Charged 2% from refundable amount. Not be refundable. </p>
                    <p>If any flight booking cancel for reason act of God the cancellation charge may be applicable (Rs 100.00 + 18%) (One hundred eighteen rupees only) including GST per passenger & Transition Charged 2% from refundable amount. Other reason for the flight ticket cancels, cancellation charge may be applying by navchr online.</p>
                    <p>Navchr Online is not responsible for any delay or cancellation of flights from airline end. Customer is recommended to get their ticket copy stamped at the airport to get full refund in such cases.</p>

                    <h3>Online Cancellations :</h3>
                    <p>Cancellations can be made online by signing in to your navchr online account. Step by step details for online cancellations are available here. Cancellation can be done 06 hours before flight departure. For more details please.</p>
                    <p>When you cancel your flight booking Cancel ticket button may be disabled before 06 hr depart of flight. Flight cancel charge (Rs 100.00 + 18%) (One hundred eighteen rupees only) including GST per passenger & Transition Charged 2% from refundable amount may be applicable.</p>
                    <p>After cancellation user may be info their information to customer care by email (<b><i>support@navchr.com</i></b>) and WhatsApp number with in 6hr ago from flight departure.</p>

                    <h3>Process of online cancellations :</h3>
                    <p>First Login your user ID after go to my Booking After go to flight ticker and click cancel ticket button and fill the from after ok after enter OTP and submit. This process is available before 06 hr depart of flight, please insure it.</p>

                    <h3>Offline Cancellations :</h3>
                    <p>We also not accept cancellation requests through phone and email. However navchr online cancellation charges for cancellations and transition may be higher from users (Cancellation Charge Rs 100.00/- (Rupees One hundred only) per passenger & Transition Charged 2% from refundable amount).</p>
                    <p>In addition to the airline's cancellation charges, Navchr Online charges a non-refundable cancellation fee of (Rs 100.00 + 18%) (One hundred eighteen rupees only) including GST per passenger & Transition Charged (2%) per segment within India for domestic airlines bookings.</p>
                    <p>Only cancellation requests made online or telephonically through our customer support shall be entertained. Navchr Online shall not be liable to entertain any cancellation requests made through any other medium including but not limited to sms, email. And WhatsApp chats. </p>
                    <p>If your cancellation request is made minimum 6 hours ago from flight departure, you'll need to contact the airline company directly to get your reservation cancelled.</p>
                    <p>If you have done a 'Web/Tele Check-in' with the airline, get in touch with the airline for cancellation. Navchr Online will not be able to process the refund for the same and will not take any responsibility for the polices.</p>
                    <p>Convenience fee charged at the time of booking is non-refundable and cancellation charge may be applied from Navchr Online.</p>
                    <p>For any cancellation made by you for which refund is already processed and if it is found that you have used/ made changes to the ticket on the same PNR directly with the airline, navchr online reserves the right to debit your original mode of payment against the amount which has been refunded to you.</p>
                    <p>For within India Domestic bookings, if you cancel your ticket offline through our call centre a non-refundable fee of (Rs 100.00 + 18%) (One hundred eighteen rupees only) including GST per passenger per ticket will be charged in addition to airline's cancellation charges. This is applicable for very dangerous problem. Transition charge 2% is applicable in refund amount. This condition may be applied before 06hr departure the flights. This problem only register by register mobile number.</p>
                </div>
            </div>
        </div>
    )
}
