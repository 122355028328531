import { Visibility } from '@material-ui/icons';
import { ConnectingAirports, Flight } from '@mui/icons-material';
import { format, formatDistanceStrict, parseISO } from 'date-fns';
import React, { useEffect } from 'react'
import { useState } from 'react';

export default function StatusPageFlightInfo(props) {

    const [flightSegments, setFlightSegments] = useState(null);
    const [passengersDeatils, setPassengersDeatils] = useState(null);

    useEffect(() => {
        console.log(props)
        setFlightSegments(props?.sendBookData?.Segments);
        setPassengersDeatils(props?.sendBookData?.Passangers);
    }, [props]);
    return (
        <div>
            {flightSegments !== null && <>
                {/* {JSON.stringify(flightSegments)}
            {JSON.stringify(passengersDeatils)} */}
                <div className="flightSummery   ">


                    <div className="popUp text-uppercase">
                        {flightSegments[0]?.origin_city_name}
                        <span className='px-2'> <img src="/img/f.svg" alt="" /></span>
                        {flightSegments[flightSegments?.length - 1]?.destination_city_name}
                    </div>

                    <div className="inside">
                        <div className="l1 d-flex flex-wrap align-items-center">

                            <figure className={`d-inline-flex me-3   ${flightSegments[0]?.airline_name}`}>
                                <img
                                    src={`/img/AirlineLogo/${flightSegments[0]?.airline_name}.png`}
                                    alt={flightSegments[0]?.airline_name}
                                />
                            </figure>

                            <div>
                                {flightSegments[0]?.airline_name}  {flightSegments[0]?.craft} -
                                {flightSegments[0]?.cabin_class === 2 && 'Economy'}
                                {flightSegments[0]?.cabin_class === 3 && 'Premium Economy'}
                                {flightSegments[0]?.cabin_class === 4 && 'Business'}
                                {flightSegments[0]?.cabin_class === 6 && 'First'}
                            </div>
                            <div className="px-4">|</div>
                            <div  className='alart  '>
                                <ConnectingAirports />  {flightSegments?.length === 1 && 'Non stop'}
                                {flightSegments?.length > 1 && <>
                                    {flightSegments?.map((data, ind) =>
                                        <i>
                                            {flightSegments[ind + 1]?.origin_city_name &&
                                                <small>  {ind + 1} stop via <b className='text-uppercase'>{flightSegments[ind + 1]?.origin_city_name}</b> - {flightSegments[ind + 1]?.origin_airport_name} - T{flightSegments[ind + 1]?.destination_terminal} - {format(parseISO(flightSegments[ind + 1]?.dep_time), 'EEE, dd MMM yyyy, HH:MM')}  <br />
                                                </small>}
                                        </i>
                                    )}
                                </>}
                            </div>
                        </div>
                        <div className="l2">
                            <div className="row">
                                <div className="col text-end">
                                    <b className='text-uppercase'> {flightSegments[0]?.origin_airport_code} {format(parseISO(flightSegments[0]?.dep_time), 'HH:MM')} </b> <br />
                                    {format(parseISO(flightSegments[0]?.dep_time), 'EEE, dd MMM yyyy')}  <br />
                                    Terminal {flightSegments[0]?.destination_terminal || 'N/A'} <br /> <small>{flightSegments[0]?.origin_airport_name}</small>
                                </div>
                                <div className="col-auto"><img src="/img/f.svg" alt="" /></div>
                                <div className="col">
                                    <b className='text-uppercase'>{format(parseISO(flightSegments[flightSegments?.length - 1]?.arr_time), 'HH:MM')} {flightSegments[flightSegments?.length - 1]?.destination_city_name}</b> <br />
                                    {format(parseISO(flightSegments[flightSegments?.length - 1]?.arr_time), 'EEE, dd MMM yyyy')}  <br />
                                    Terminal {flightSegments[flightSegments?.length - 1]?.destination_terminal || 'N/A'} <br /> <small>{flightSegments[flightSegments?.length - 1]?.destination_airport_name}</small>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="l3">
                            <ul className="list-unstyled d-md-flex flex-wrap">
                                {passengersDeatils?.map((data, ind) => (
                                    <li class="d-flex align-items-center">
                                        <figure>
                                            {data?.pax_type === 1 && <img src="/img/a.svg" style={{width:'3.5rem'}} alt="" />}
                                            {data?.pax_type === 2 && <img src="/img/c.svg" style={{width:'3.5rem'}} alt="" />}
                                            {data?.pax_type === 3 && <img src="/img/b.svg" style={{width:'3.5rem'}} alt="" />}
                                        </figure>

                                        <div>
                                            {data?.title}. {data?.first_name} {data?.last_name}  <br />
                                            Age:{formatDistanceStrict(new Date(data?.date_of_birth), new Date(), { unit: 'year' })}
                                           &#160; - {data?.gender === 1 ? 'Male' : 'Female'}   -
                                           &#160;{data?.pax_type === 1 && 'Adult'}

                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}
