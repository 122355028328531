import { Facebook, Instagram, Twitter, YouTube } from '@material-ui/icons';
import React from 'react';
 
export default function DirectorIntro() {
    return (
        <>
            <div className="DirectorIntroWarper">
                <div className="container">
                    <div className="grid_12">
                        <div className="banner_capt">
                            <div className="row align-items-center justify-content-center hangBox py-3   shadow-lg">
                                <div className="col-md-3">
                                    <figure className='  mb-0'><img src="/img/Tanusri-removebg-preview.png" alt="" className='img-fluid   ' draggable="false" /></figure>
                                    <div className="text-center pt-3">
                                        <h4>Follow Me</h4>
                                        <ul className='list-unstyled d-flex justify-content-center'>
                                            <li>
                                                <a href="https://www.facebook.com/" target='_blank' className=" "><Facebook/> </a>

                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/" target='_blank' className=" "><Instagram /> </a>

                                            </li>
                                            <li>
                                                <a href="https://twitter.com/tanusri65h" target='_blank' className=" "><Twitter /> </a>

                                            </li>
                                            {/* <li>
                                                <a href="/" className=" "><i className="icon-linkedin-sign"></i> </a>
                                            </li> */}
                                            <li>
                                                <a href="https://www.youtube.com/channel/UCbNJgHxPbR2sTa4ICZfTVvg" target='_blank' className=" "><YouTube /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-8 py-md-4">
                                    <div className=" ">
                                        <div className="title1">Mrs Tanusri Santra   </div>
                                        <h5 ><i>Founder and CEO</i></h5>
                                    </div>
                                    <div className="title2 font-italic mb-4">Dear Users,
                                        First of all, we like to say an enormous thank you for the constant support you offer to NAVCHR ONLINE. With your help, the corporation remains to raise in all invention sect. I am sure that you have qualified a fruitful and gainful season very much.  <br />

                                        Our effort is always on providing the best cost for both domestic and international airlines. https://www.navchr.com/ www.navchr.in is proud to have more airlines dealers joined than any other stage in India. This means that we offer the broadest series of outbound airlines in the Indian subcontinent. They improve our facilities and certify we constantly afford the best rates to all our users. We have just updated our airlines packages sections. I am personally intricate on a day-to-day basis in creating certain that navchr online develops a leader in the trips vertical. I wish you to occupy some time receiving to know our online airlines product, which is leading in both list and pricing. Should you have any queries connected to this, please free to call on +91 9336111631(09:00am - 05:00pm) email : support@navchr.com everyday.</div>


                                </div>
                            </div>
                            {/* <a href="/" className="link border-right border-white"><i className="icon-facebook-sign"></i>Follow </a>
                            <a href="/" className="link  border-right border-white"><i className="icon-twitter-sign"></i>Follow </a>
                            <a href="/" className="link"><i className="icon-linkedin-sign"></i>Follow </a> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
