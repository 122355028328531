import { getOptionsFromChildren } from '@mui/base';
import React, { useEffect } from 'react';
import { json, useParams } from 'react-router-dom';
import AddressForm from '../Slices/AddressForm';
import BookingForm from '../Slices/BookingForm';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Check } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { API_BASE_LINK } from '../API_BASE_PATH';
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const BookingSuccessfullPage = () => {
    const { id } = useParams();

    useEffect(() => {
        // use the id variable here
        console.log(`Booking ID: ${id}`);

        axios.get(`${API_BASE_LINK}flight/details/${id}`)
        .then(function (response) {
            // console.log(response.data)
            if (response.status === 200) {
                console.log(response.data);
                
                // navigate('/booking-successfull')
            }
        }).catch(function (error) {
            console.log(error);
        });
    }, [id]);


    return (
        <main className='BOOKING_PAGE_MAIN_WRAP'>
            <div className="main_round_one">
                <img src="/img/round1.svg" alt="" />
            </div>
            <div className="main_round_two small">
                <img src="/img/round2.svg" alt="" />
            </div>


            <div className="common_banner mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <div className="iconBox"><span><img src="/img/ico13.svg" alt="" /></span></div>
                        </div>
                        <div className="col">
                            <h4>Payment Success!</h4>
                            <p className="mb-0">
                                Your payment has been processed! Details of transactions are included below.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4"></div>

            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className='cart_layer'>
                                <div className="cart_layer_inner">

                                    <div className="top_extra_pan">
                                        <div className="row justify-content-around pt-4">
                                            <div className="col-md-auto">
                                                <h4>PNR:</h4>
                                                <h5>AD1234568745</h5>
                                            </div>
                                            <div className="col-md-auto">
                                                <h4>Trip ID:</h4>
                                                <h5>923156391223</h5>
                                            </div>
                                            <div className="col-md-auto">
                                                <h4>Ticket Issue Date:</h4>
                                                <h5>21 Dec, 2023 </h5>
                                            </div>
                                            <div className="col-md-auto">
                                                <h4>Booking Status:</h4>
                                                <h5 className='green'>Ticket Confirmed</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="d-flex flight_card align-items-center"  >
                                        <div className="box leftBox">
                                            <div className="inside">

                                                <figure className={`d-inline-flex Air Asia `}>
                                                    <img src={`/img/AirlineLogo/Air Asia.png`} alt={`Air Asia`} />
                                                </figure>
                                                {/* <figure className='d-inline-flex red'>
                                                                        <img src="/img/AirlineLogo/AK.gif" alt="" />
                                                                    </figure> */}
                                                <div className="tire1 mb-2">
                                                    <b>Kolkata</b>
                                                    <span>2023-01-29  07:30</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="box middleBox d-flex flex-column justify-content-around">

                                            <div className="inside">

                                                <div className="line2 d-flex align-items-center justify-content-between ">
                                                    <div className="pos">
                                                        <b>07:30</b>
                                                        <div>
                                                            <div className="carcle"><em></em></div>
                                                        </div>
                                                        <span>CCU</span>
                                                    </div>
                                                    <div className="time">
                                                        <span>2h 20m</span>
                                                        <div className="py-1">
                                                            <img src="/img/ico12.svg" alt="" />
                                                        </div>
                                                        <span>Non-Stop</span>
                                                    </div>
                                                    <div className="pos">
                                                        <b>09:50</b>

                                                        <div>
                                                            <div className="carcle two"><em></em></div>
                                                        </div>
                                                        <span>HYD</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="box righttBox">
                                            <div className="inside">


                                                <div className="line5">
                                                    <b>Hyderabad</b> <br />
                                                    <span>2023-01-29 </span> <br />
                                                    <span>09:50</span>
                                                    {/* <img src="/img/ico9.svg" alt="" /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="trext-right pt-4 pb-5 text-end pe-md-4">
                                        <a href='test.pdf' className='btn cust_btn_one' download>
                                            <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.84 13.8129L12.8338 8.57143L14 9.79571L7 17.1429L0 9.79571L1.1662 8.57143L6.16 13.8129V0H7.84V13.8129ZM0 18.2857H14V20H0V18.2857Z" fill="white" />
                                            </svg>

                                            <span className='ps-2'>download ticket</span></a>
                                    </div>


                                </div>
                            </div>

                            <div>



                            </div>


                        </div>
                        <div className="col-md-4">
                            <div className="Layer_Box_Two">
                                <div className="box px-3">


                                    <div className="layer_two">
                                        <h4 className='mb-0 lh-n'>Travellers</h4>
                                        <hr />
                                        <ul className="passanger_group_type_two list-unstyled">
                                            {[1, 2, 3].map((data, ind) => (
                                                <li key={ind}>
                                                    <h6>1- John Doe</h6>
                                                    <p>E-Tickets: 9804123456 | <span>Booked</span></p>
                                                </li>
                                            ))}
                                        </ul>


                                        <div className="final_pay_layer border-bottom-0">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="left">
                                                    <span className='m-0'>Total fare</span>
                                                </div>
                                                <div className="right">
                                                    ₹15022.84
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </main>
    );
}

export default BookingSuccessfullPage;
