import React, { useEffect, useState } from 'react'
import { FormControl, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useNavigate, Link } from 'react-router-dom';
import AttachEmail from '@mui/icons-material/AttachEmail';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { API_BASE_LINK } from "../API_BASE_PATH";
import axios from "axios";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);


export default function ForgotPassWord() {
    const [SendEmail, setSendEmail] = useState(false);
    const [SetEmail, setSetEmail] = useState('');
    const [EnterValidEmail, setEnterValidEmail] = useState('');
    const navigate = useNavigate();
    const handelEmailSuccessfully = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        console.log(SetEmail);
        // if (SetEmail !== '') {
        //     setSendEmail(true);
        if (SetEmail === '') {
            setEnterValidEmail('Can not be empty.');
        }
        else if (regex.test(SetEmail) === false) {
            // errors.email = "Wrong email";
            console.log("Wrong email");
            setEnterValidEmail('Enter valid email ID');
        }
        else {
            console.log("Good email");
            axios.post(`${API_BASE_LINK}users/requestResetPassword`, {
                "email_id": SetEmail
            }).then(function (response) {
                console.log('----------------------', response);
                if (response.status === 200) {
                    setSendEmail(true);
                }
            }).catch(function (error) {
                console.log(error);
                alert(JSON.stringify(error?.response?.data?.error + 'Or Email ID not correct'))
            });

        }
        // }
    }
    useEffect(() => {
        if (sessionStorage.getItem("islogin") === 'true') {
            navigate('/')
        }
    }, []);

    return (

        <main className='BOOKING_PAGE_MAIN_WRAP'>

            <ThemeProvider theme={darkTheme}>

                <div className="common_banner  ">
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div className="iconBox"><span><img src="/img/ico13.svg" alt="" /></span></div>
                            </div>
                            <div className="col">
                                <h3>Forgot Password</h3>
                                <p className="mb-0">
                                    To reset your password, please enter your navchr.com registered email ID.
                                    navchr.com will send the password reset instructions to the email address for this account.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login_wraper py-5">


                    <div className="container py-5">


                        <div className="row">
                            <div className="col-md-7 mx-auto">
                                <div className="login_inner">
                                    <div className="text-center mb-5">
                                        <h3>Request Reset   Link</h3>
                                        <p>Enter your email address below and <br />    we'll send you a link to reset your password. Check your email box</p>
                                    </div>

                                    {SendEmail ?
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className='text-center mb-5'>We have send a link on <b className="text-dark">{SetEmail}</b> to reset your password and you should receive  it shortly.</h4>
                                            </div>
                                            <div className="col-12">

                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <div className="loginBtn">
                                                            <button
                                                                onClick={() => setSendEmail(false)}
                                                                className="btn cust_btn_one border-0">
                                                                <AttachEmail /> &#160; Edit email
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        Back to <Link to="/login"> <b>Login</b></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row">
                                            <div className="col-md-12 mb-4">
                                                <TextField id="outlined-basic" label="Email ID"
                                                    autoComplete='false'
                                                    type="text"
                                                    required
                                                    variant="outlined" className="w-100" onChange={(e) => setSetEmail(e.target.value)} />

                                                {EnterValidEmail !== '' && <p className='text-error pt-2 mb-0'>
                                                    {EnterValidEmail}
                                                </p>}
                                            </div>


                                            <div className="col-12">
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <div className="loginBtn">
                                                            <button className="btn cust_btn_one border-0"
                                                                onClick={() => handelEmailSuccessfully()}>
                                                                <AttachEmail /> &#160; Send Reset Link
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        Remember your <Link to="/login"> <b>password ?</b></Link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <div className="col-12 text-center pt-5">
                                        <hr />
                                        Don’t have an account? <Link to='/register'><b>Join navChr</b></Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </ThemeProvider>
        </main >
    )
}
