import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddressForm from '../Slices/AddressForm';
import BookingForm from '../Slices/BookingForm';

import { CircularProgress, Snackbar, ThemeProvider, createTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { API_BASE_LINK } from '../API_BASE_PATH';
import FlightSummery from '../Slices/FlightSummery';
import Timer from '../Slices/Timer';
import AddPassangerCard from '../Slices/AddPassangerCard';
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const BookingPage = () => {
    const navigate = useNavigate();

    const pageParams = useParams();

    const [FlightQuote, setFlightQuote] = useState({});
    const [IsPassengerDetailsSuccess, setIsPassengerDetailsSuccess] = useState(false);
    const [FlightDetails, setFlightDetails] = useState(null);
    const [FlightSegments, setFlightSegments] = useState(null);
    const [ReturnFlightSegments, setReturnFlightSegments] = useState(null);
    const [Time_end, setTime_end] = useState(false);
    const [GetDiffSecond, setGetDiffSecond] = useState(0);
    const [StoreParamsData, setStoreParamsData] = useState(null);
    const [ErrorResponce, setErrorResponce] = useState(null);
    let [address, setAddress] = useState({
        "contact_no": "",
        "email": "",
        "address_line": "",
        "city": "",
        "state": "",
        "country": "",
        "zip_code": "",
        "defence_id": ""

    })
    const [IsChecked, setIsChecked] = useState({
        ckeck_one: true,
        ckeck_two: true,
        ckeck_three: true,
    });

    useEffect(() => {
        const paramData = JSON.parse(window.atob(pageParams?.id));
        setStoreParamsData(paramData);

    }, [pageParams]);





    const FilterFlightSegments = (segment, flight_index, return_flight_index) => {
        const result1 = segment?.filter((filterData) => { return filterData.result_index === flight_index });
        const result2 = segment?.filter((filterData) => { return filterData.result_index === return_flight_index });

        setFlightSegments(result1);
        setReturnFlightSegments(result2);
    }

    const flightDetailsApiCall = (data) => {
        axios.get(`${API_BASE_LINK}flight/details/${data}`)
            .then(function (response) {
                if (response?.status === 200) {
                    setFlightDetails(response?.data);
                    FilterFlightSegments(response?.data?.Segments, response?.data?.flight_index, response?.data?.return_flight_index);
                    if (response?.data?.Passangers?.length > 0) {
                        setTime_end(true)
                    }
                }
                console.log('${API_BASE_LINK}flight/details/${pageParams.id}', response);
            })
            .catch(function (error) {
                // console.log(error);
                alert('Flight Deatis Can not able to fetch.')
            });

    }

    useEffect(() => {
        const paramData = JSON.parse(window.atob(pageParams?.id));
        console.log('paramData');
        console.log(paramData);
        axios.post(`${API_BASE_LINK}flight/quote`, {
            "booking_id": paramData?.b
        })
            .then(function (response) {
                if (response?.status === 200) {
                    setFlightQuote(response?.data?.booking);
                    setFlightDetails(response?.data?.booking);
                    FilterFlightSegments(response?.data?.booking?.Segments, response?.data?.booking?.flight_index, response?.data?.booking?.return_flight_index);
                    let passanger = [...response?.data?.booking?.Passangers][0]
                    setAddress({
                        contact_no: passanger.contact_no,
                        address_line: passanger.address_line_1,
                        city: passanger.city,
                        state: passanger.address_line_2,
                        country: 'India',
                        defence_id: response?.data?.booking?.defence_id,
                        email: passanger.email,
                        zip_code: passanger.zip_code
                    })
                } else {
                    navigate(-1)

                    // console.log(pageParams)
                }
                if (response?.data?.error) {
                    setTime_end(true);
                }
                console.log(response);

                if (response?.data?.booking?.is_expired === true) {
                    setTime_end(true);
                }

            })
            .catch(function (error) {
                console.log(error);
                // console.log(pageParams)
                setTime_end(true);
                alert('You visited long back on this page. You should be search again, go to home page. ')
            });



    }, []);

    const label = 'I agree with the terms & conditions, privacy policy, booking policy, cookies policy and I understand and accept the cancellation policy and refund policy.';

    const goNextPage = () => {
        const paramData = JSON.parse(window.atob(pageParams?.id));
        let passangerPayload = {
            booking_id: paramData?.b,
            "traveller_information": {
                "passenger": FlightDetails.Passangers,
                "contact_details": address
            }
        }
        console.log(passangerPayload);

        // const checkValid = (data, field) => {
        //     if (data === null || data === undefined || data === '') {
        //         return `Please fill out ${field} field.`
        //     } else {
        //         return 'ok'
        //     }
        // }

        // const checkpassenger = passangerPayload?.traveller_information?.contact_details?.map((data, ind) => {
        //     return checkValid(data, data)
        // });



        /*
        
                function isValidValue(value) {
                    // Check if the value is not null and not undefined
                    if (value === null || value === undefined) {
                        return false;
                    }
        
                    // Check if the value is an empty string
                    if (typeof value === 'string' && value.trim().length === 0) {
                        return false;
                    }
        
                    // If the value passes both checks, it is considered valid
                    return true;
                }
        
                // Validate passenger information
                const passenger = passangerPayload?.traveller_information?.passenger[0];
                if (!isValidValue(passenger.id) || !isValidValue(passenger.title) || !isValidValue(passenger.first_name) || !isValidValue(passenger.last_name) || !isValidValue(passenger.date_of_birth) || !isValidValue(passenger.address_line_1) || !isValidValue(passenger.city) || !isValidValue(passenger.contact_no) || !isValidValue(passenger.email)) {
                    console.log('Passenger information is not valid.');
                } else {
                    console.log('Passenger information is valid.');
                }
        
                // Validate contact details
                const contactDetails = passangerPayload?.traveller_information?.contact_details;
                if (!isValidValue(contactDetails.contact_no) || !isValidValue(contactDetails.address_line) || !isValidValue(contactDetails.city) || !isValidValue(contactDetails.state) || !isValidValue(contactDetails.email)) {
                    console.log('Contact details are not valid.');
                } else {
                    console.log('Contact details are valid.');
                }
        
        
                // if(passangerPayload?.traveller_information?.contact_details?.address_line)
        */

        axios.post(`${API_BASE_LINK}flight/passangers/bulk`, passangerPayload)
            .then(function (response) {
                if (response.status === 200) {
                    navigate('/booking-cart/' + paramData?.b)
                }
            }).catch(function (error) {
                setErrorResponce(error?.response?.data?.error);
            });
    }

    useEffect(() => {
        if (sessionStorage.getItem("islogin") !== 'true') {
            navigate('/');

            // history.goBack();
        }
    }, []);

    return (
        <>
            {ErrorResponce?.map((data, ind) => <SnackErrorCard data={data} />)}
            <main className='BOOKING_PAGE_MAIN_WRAP'>

                {/* {ErrorResponce?.map((data, ind) => {
                    <Snackbar open={true}>{data}</Snackbar>
                })} */}
                {Time_end === true &&
                    <div className="modalAlrtWrap">
                        <div className="modalAlrt">
                            <h4>Unable to add passenger availability</h4>
                            <hr />
                            <p>
                                Sorry, we weren't able to confirm flight availability due to session expired. <br /> Please search flight again.
                            </p>
                            <Link to='/' className='btn cust_btn_one'>Search again</Link>
                        </div>
                    </div>
                }

                {Time_end === false &&
                    <>

                        <div className="common_banner mb-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="iconBox"><span><img src="/img/ico1.svg" alt="" /></span></div>
                                    </div>
                                    <div className="col">
                                        <h4>Best choice! We can not guarantee this price for too long, select in this flight before it is gone!</h4>
                                        <p className="mb-0">
                                            Please fill passenger form with in  <span>
                                                <b className='text-dark'  >
                                                    {FlightDetails?.seconds_left_to_expire && <Timer getDate={FlightDetails?.seconds_left_to_expire} />}
                                                </b>
                                            </span>, After that this form will be closed and search flight again.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-4"></div>

                        {FlightDetails !== null ? <>
                            <section className="">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-9">
                                            {FlightDetails?.flight_index !== null &&
                                                <>
                                                    <FlightSummery
                                                        flight_index={FlightDetails?.flight_index}
                                                        fares={FlightDetails?.Fares[0]}
                                                        flightSegments={FlightSegments}
                                                        type="Departure flight details" />
                                                </>
                                            }
                                            {FlightDetails?.return_flight_index !== null &&
                                                <>
                                                    <>
                                                        <FlightSummery
                                                            flight_index={FlightDetails?.return_flight_index}
                                                            fares={FlightDetails?.Fares[1]}
                                                            flightSegments={ReturnFlightSegments}
                                                            type="Return flight details" />
                                                    </>
                                                </>
                                            }

                                            {FlightDetails.Passangers.map((data, index) => (
                                                <div className="" key={data.id + 'p'}>
                                                    <BookingForm passenger={data} idx={index} handleChange={(idx, passenger) => {
                                                        let flight_details = { ...FlightDetails }
                                                        let passengers = [...flight_details.Passangers]
                                                        passengers[idx] = passenger
                                                        flight_details.Passangers = passengers;
                                                        setFlightDetails(flight_details)
                                                    }} />
                                                </div>
                                            ))}

                                            <AddressForm address={address} isDefence={FlightDetails?.is_defence} onChange={value => {
                                                setAddress(value)
                                            }} />


                                            {/* <button type='button' onClick={() => {
                                                console.log("traveller_information",
                                                    {
                                                        "passenger": FlightDetails.Passangers,
                                                        "contact_details": address
                                                    }
                                                )
                                            }}>Check Passenger Array</button> */}

                                            <div>
                                                <div className="Layer_Box_Two "  >
                                                    <div className="box form_box_common mb-4">
                                                        <h4>Secure Your Trip</h4>
                                                        <p>We require a valid email address to contact you with the details of your booking.</p>
                                                        <hr />

                                                        <ThemeProvider theme={darkTheme}>
                                                            <p>
                                                                <Checkbox
                                                                    color="default"
                                                                    checked={IsChecked.ckeck_one}
                                                                    onChange={(e) => (setIsChecked({ ...IsChecked, ckeck_one: e.target.checked }))} />
                                                                <span>No assistance and insurance required/I will risk it.</span>
                                                            </p>
                                                            <p>
                                                                <Checkbox
                                                                    color="default"
                                                                    checked={IsChecked.ckeck_two}
                                                                    onChange={(e) => (setIsChecked({ ...IsChecked, ckeck_two: e.target.checked }))} />
                                                                <span>Your flight booking cancel refundable amount depend on guidelines of airline company.</span>
                                                            </p>

                                                        </ThemeProvider>


                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="Layer_Box_Two "  >
                                                    <ThemeProvider theme={darkTheme}>

                                                        <p className=''>
                                                            <Checkbox
                                                                color="default"
                                                                checked={IsChecked.ckeck_three}
                                                                onChange={(e) => (setIsChecked({ ...IsChecked, ckeck_three: e.target.checked }))} />
                                                            <span>I agree with the <Link className='ancher_hover' target='_blank' to='/terms-and-conditions'>terms & conditions</Link>, <Link className='ancher_hover' target='_blank' to="/privacy-policy">privacy policy</Link>, <Link className='ancher_hover' target='_blank' to='/booking-policy'>booking policy</Link>, <Link className='ancher_hover' target='_blank' to='/'>cookies policy</Link> and I understand and accept the <Link className='ancher_hover' target='_blank' to='/cancellation-policy'>cancellation policy</Link> and <Link className='ancher_hover' target='_blank' to='/refund-policy'>refund policy</Link>.</span>
                                                        </p>
                                                    </ThemeProvider>
                                                </div>
                                                {IsPassengerDetailsSuccess && <div >  <h5 className='bg-warning text-dark py-3 shadow-lg px-4 rounded text-center'>{IsPassengerDetailsSuccess}</h5></div>}
                                                {IsChecked.ckeck_three === false && <div >  <h5 className='bg-warning text-dark py-3 shadow-lg px-4 rounded text-center'>All above checkbox should be checked.</h5></div>}
                                                <div className="trext-right pt-3 pb-5 pe-4 text-end">

                                                    {/* <button className='btn cust_btn_one' onClick={() => navigate('/booking-cart')} >continue</button> */}
                                                    {FlightDetails?.Passangers?.length > 0 && <button className='btn cust_btn_one' onClick={() => goNextPage()} >continue</button>}
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-md-3 mb-5">
                                            <div className="Layer_Box_Two">
                                                <div className="box px-3">
                                                    <div className="layer_two">
                                                        <h4>Pricing</h4>
                                                        <hr />
                                                        <div className="passanger_group">
                                                            <table className='table  text-white rounded-3'>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Description</th>
                                                                        <th className='text-end'>Amount</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total Base Fare </td>
                                                                        <td className='text-end'>₹ {(FlightDetails?.DisplayFare?.total_base_fare).toLocaleString()}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total Taxes </td>
                                                                        <td className='text-end'>₹ {(FlightDetails?.DisplayFare?.total_taxes).toLocaleString()}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Total Airfare</b></td>
                                                                        <td className='text-end'><b>₹ {(FlightDetails?.DisplayFare?.total_airfare).toLocaleString()}</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Convenience Fee</td>
                                                                        <td className='text-end'> ₹ {(FlightDetails?.DisplayFare?.total_convenience_fee).toLocaleString()}</td>
                                                                    </tr>





                                                                </tbody>

                                                            </table>
                                                        </div>

                                                        <div className="final_pay_layer">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="left">
                                                                    You will be <br /> charged  total
                                                                </div>
                                                                <div className="right">
                                                                    ₹ {(FlightDetails?.DisplayFare?.grand_total).toLocaleString()}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="extra_layer">
                                                            <h4>Payment Method </h4>
                                                            <p>
                                                                navchr online use a most wonderful payment gateway for all persons, all user are request that please choose the online transition in this air booking travel  website. If you used net banking, credit card, all types debit card and other payment mode very low transition charge may be applied, if you choose UPI and rupay debit card transition charge not be apply.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </> : <>
                            <div className="text-center">
                                <CircularProgress />
                            </div>
                            <h5 className='text-center pb-5'>Please wait <br /> Loading your selected flight details.</h5>
                        </>}

                    </>
                }

            </main>
        </>
    );
}

export default BookingPage;


const SnackErrorCard = ({ data }) => {
    return (
        <>
            <Snackbar
                open={true}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <p className='bg-warning p-2 text-dark rounded-2'>
                    {data}
                </p>
            </Snackbar>
        </>
    )
}