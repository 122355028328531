import React, { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { TextField } from '@mui/material';
import STATE from '../DATA_SET/IndianStates.json';
import CountryList from '../DATA_SET/country_list.json'
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

// address_line_1: "Flat No. 102, Block B",
//     address_line_2: "Not requre",
//         city: "Mumbai",
//             state: "Mumbai",
//                 country_name: "India",
//                     pin: ""

const AddressForm = ({ address, isDefence, onChange }) => {
    const [SET_STATE, setSET_STATE] = React.useState(-1);
    const [ADDRESS_DETAILS, setADDRESS_DETAILS] = React.useState({
        "contact_no": "",
        "email": "",
        "address_line": "",
        "city": "",
        "state": "",
        "country": "India",
        "zip_code": "",
        "is_defence": 'No Defence Id',
        "person": {
            "type": 1,
            "name": "Indian Army",
        }
    });




    useEffect(() => {
        console.log(address)
    }, [address]);

    const StateHandleChange = (event) => {
        console.log('please ---', event.target.value)
        setSET_STATE(event.target.value);
        onChange({
            ...address,
            state: STATE[event.target.value].name
        })
        // if (event.target.value !== -1) {

        //     setADDRESS_DETAILS({ ...ADDRESS_DETAILS, state: STATE[event.target.value].name });

        // } else {
        //     console.log('please select one')
        //     setADDRESS_DETAILS({ ...ADDRESS_DETAILS, state: STATE[event.target.value].name });

        // }
    };

    useEffect(() => {
        setADDRESS_DETAILS({
            "contact_no": address?.contact_no,
            "email": address?.email,
            "address_line": address?.address_line,
            "city": address?.city,
            "state": address?.state,
            "country": address?.country,
            "zip_code": address?.zip_code,
            "defence_id": address?.defence_id,

        });
        setSET_STATE(STATE.findIndex(obj => obj.name === address?.state))
    });

    // useEffect(() => {
    //     onChange(ADDRESS_DETAILS)
    // }, [ADDRESS_DETAILS, SET_STATE])
    return (
        <div>
            <div>
                <div className="Layer_Box_Two "  >
                    <div className="box form_box_common mb-3">
                        <h4>Contact Info</h4>
                        <p>We require a valid email address to contact you with the details of your booking.</p>
                        <p>
                            Please fill all mandatory fields (*).
                        </p>
                        <hr />
                        <ThemeProvider theme={darkTheme}>
                            <div className="row">
                                {isDefence &&
                                    <div className="col-md-12 mb-3">
                                        <FormControl fullWidth>
                                            <div className='label' >Defence ID *</div>
                                            <TextField
                                                InputProps={{ sx: { height: '3rem' } }}
                                                value={address?.defence_id}
                                                variant="outlined" onChange={(e) => {
                                                    onChange({
                                                        ...address,
                                                        defence_id: e.target.value
                                                    })
                                                    //setADDRESS_DETAILS({ ...ADDRESS_DETAILS, is_defence: e.target.value }) 
                                                }} />
                                        </FormControl>
                                    </div>
                                }
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <div className='label' >Email Address *</div>
                                        <TextField
                                            autoComplete="off"
                                            InputProps={{ sx: { height: '3rem' } }}
                                            value={address?.email}
                                            variant="outlined" onChange={(e) => {
                                                onChange({
                                                    ...address,
                                                    email: e.target.value
                                                })
                                                //setADDRESS_DETAILS({ ...ADDRESS_DETAILS, email: e.target.value }) 
                                            }} />
                                    </FormControl>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <div className='label' >Phone Number *</div>
                                        <TextField
                                            InputProps={{ sx: { height: '3rem' } }}
                                            value={address?.contact_no}
                                            variant="outlined" onChange={(e) => {
                                                onChange({
                                                    ...address,
                                                    contact_no: e.target.value
                                                })
                                                //setADDRESS_DETAILS({ ...ADDRESS_DETAILS, contact_no: e.target.value }) 
                                            }} />
                                    </FormControl>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <FormControl fullWidth>
                                        <div className='label' >Address Line *</div>
                                        <TextField variant="outlined"
                                            InputProps={{ sx: { height: '3rem' } }}
                                            value={address?.address_line}
                                            onChange={(e) => {
                                                onChange({
                                                    ...address,
                                                    address_line: e.target.value
                                                })
                                                //setADDRESS_DETAILS({ ...ADDRESS_DETAILS, address_line: e.target.value })
                                            }} />
                                    </FormControl>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <div className='label' >City *</div>
                                        <TextField
                                            variant="outlined"
                                            value={address?.city}
                                            InputProps={{ sx: { height: '3rem' } }} onChange={(e) => {
                                                onChange({
                                                    ...address,
                                                    city: e.target.value
                                                })
                                                //setADDRESS_DETAILS({ ...ADDRESS_DETAILS, city: e.target.value })
                                            }} />
                                    </FormControl>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <div className='label' >State *</div>

                                        <Select
                                            style={{ height: '3rem' }}
                                            value={SET_STATE}
                                            onChange={(e) => {
                                                StateHandleChange(e);
                                            }}
                                        >
                                            <MenuItem value={-1} >Select state</MenuItem>
                                            {STATE.map((data, ind) => (
                                                <MenuItem value={ind} key={ind}>{data?.name}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <div className='label' >Country</div>
                                        {/* <TextField variant="outlined"
                                            // disabled
                                            InputProps={{ sx: { height: '3rem' } }}
                                            value={address.country}
                                            onChange={(e) => {
                                                onChange({
                                                    ...address,
                                                    country: e.target.value
                                                })
                                                //setADDRESS_DETAILS({ ...ADDRESS_DETAILS, country_name: e.target.value })
                                            }} /> */}
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={address.country}
                                            onChange={(e) => {
                                                onChange({
                                                    ...address,
                                                    country: e.target.value
                                                })
                                            }}
                                            IconComponent={ExpandMore}
                                            sx={{ height: '3.3rem' }}
                                        >
                                            {CountryList?.map((data, ind) => (
                                                <MenuItem key={ind * 3} value={data?.country}>{data?.country}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <FormControl fullWidth>
                                        <div className='label' >Pincode/ Zip Code *</div>
                                        <TextField variant="outlined"
                                            InputProps={{ sx: { height: '3rem' } }}
                                            value={address.zip_code}
                                            onChange={(e) => {
                                                onChange({
                                                    ...address,
                                                    zip_code: e.target.value
                                                })
                                                //setADDRESS_DETAILS({ ...ADDRESS_DETAILS, zip_code: e.target.value }) 
                                            }} />
                                    </FormControl>
                                </div>


                            </div>
                        </ThemeProvider>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default AddressForm;
