import React from 'react'

export default function CookiePolicy() {
    return (
        <div className='tc_cms_pages_wrapper  py-5 animate__animated animate__fadeIn'>

            <div className="container">

                <div className='main'>
                    <h1><span>Cookies Policy</span></h1>

                    <h3>What is a cookie?</h3>
                    <p>A cookie is a small file installed on your device (e.g., computer or mobile phone) when you visit a website. Cookies allow website operators to store and retrieve data from your device for several purposes, as outlined below.</p>
                    <p>The term ‘cookies’ includes similar technologies for collecting and storing information, such as Local Shared Objects (commonly referred to as "flash cookies"), pixel tags, Software Development Kits (commonly referred to as “SDKs”) and web beacons or web bugs (including transparent or clear gifs).</p>

                    <h3>Why are cookies useful?</h3>
                    <p>Cookies may be used for several purposes. From a technical standpoint, cookies allow the website to be displayed properly and function efficiently and to adapt to your preferences (e.g., your language). In addition, cookies may also be used by website operators to collect statistical information to improve their services and visitor engagement and/or to personalize website visitors’ experience.</p>

                    <h3>Types of cookies</h3>
                    <h4>There is a distinction between:</h4>
                    <h4>By entity managing the domain:</h4>
                    <p><b>First-party cookies</b>: A first-party cookie is set by the website being visited.</p>
                    <p><b>Third-party cookies</b>:  A third-party cookie is issued by a party other than website being visited.</p>

                    <h3>By expiry:</h3>

                    <p><b>Session cookies</b>: Session cookies allow actions to be tracked during a single. </p>
                    <p><b>Browser session</b>: They are deleted from your device when you close the browser.</p>

                    <p><b>Persistent cookies</b>:  Persistent cookies remain on your device between sessions and allow a website to “recognize” a user on their return, and to remember their preferences. These cookies will remain stored on your device until deleted or when they have reached a specified expiry date.</p>

                    <h3>By purpose:</h3>
                    <p><b>Technical cookies</b>:  These cookies are those that are strictly necessary for the website to function and be displayed in your device as intended by the website operator. This includes purposes such as managing website traffic, applying communication protocols between your device and the website, and allowing you to access and use the website and its contents in a secure manner.</p>
                    <p><b>Personalization cookies</b>: These cookies are those that allow to retain information so that the website visitor can access the service with certain characteristics that can differentiate the visitor’s experience from that of other visitors.</p>
                    <p><b>Analytical or performance cookies</b>: These cookies allow website operators to count visits and traffic sources to obtain statistical information and improve the performance of the website. To do this, by way of cookies, your browsing activity on the website is analysed.</p>
                    <p><b>Behavioural advertising cookies</b>: These are cookies designed and used to manage, in the most efficient manner as possible, the advertising spaces that, where appropriate, have been included on the website. These cookies store information on the user’s behaviour obtained through the continuous observation of their browsing habits, which allows a specific profile to be developed to display advertising according to said behaviour.</p>
                    <p><b>Social media cookies</b>: Some websites contain embedded share buttons or widgets that allow website visitors to connect and share content through several social networking sites. These social networking sites may set cookies that can identify you as an individual.</p>

                    <h3>Opting out of cookies</h3>
                    <p>We use cookies to ensure that we give you the best experience on our website, and to allow us to improve our site. We use cookies only if you consent (except for those that are strictly necessary).  You can manage your consent in the Privacy Preference Center.</p>
                    <p>Please be aware that if you choose to delete or restrict cookies, some features of this website may not work. You can find all details in the Privacy Preference Center.</p>

                    <h3>How we use cookies</h3>
                    <h3>We use session and persistent cookies for the following purposes:</h3>

                    <p><b>Technical cookies</b> - These cookies are strictly necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site may not work or be displayed properly.</p>
                    <p><b>Analytical or performance cookies</b> – These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.    If you do not allow these cookies we will not know when you have visited our site and will not be able to monitor its performance.</p>
                    <p><b>Personalization cookies</b> – These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly. </p>
                    <p><b>Behavioural advertising cookies</b> – These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. If you do not allow these cookies, you will experience less targeted advertising.</p>
                    <p>We may use third party service providers to perform these services for us, and when this is the case, the cookies may be set by a third party (third party cookies) with our permission. The third-party service providers may share information with other parties. The third-party cookies are identified in the “Privacy Preference Center”, within each of the categories aforementioned, under ¨Cookie details¨. </p>

                    <h3>Embedded content :</h3>
                    <p>Sometimes we embed images, videos, or other content from third party websites. As a result, when you visit the page featuring this content you may be presented with cookies from these websites. We do not control these cookies and cannot prevent these sites or domains from collecting information on your use of this content. You should check the relevant party’s website for more information.</p>
                    <p>For more information about privacy and data protection, please see our Privacy Statement.</p>

                </div>
            </div>
        </div>
    )
}
