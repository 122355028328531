import React from 'react'

export default function NewsPage() {
    return (
        <main>
            <section className='tc_cms_pages_wrapper  py-5  '>
                <div className="container">
                    <div className="row">
                        <div className="main">
                            <h1>NavChr Article</h1>
                            <hr />
                            <p>
                                Navchr online is designed to bring the world into your hands in a minute. Its purpose is to help all the defences personnel and all others persons to visit the world in any time easily. This website design by the wife of a Indian Army serving person for the help of all defence persons and all others persons. The booking system in this website is very easy and the money transfer gates is also very safe and secured and all persons personal data very safe and secured and do not share all persons personal data in our navchr online website. Our website can easily and affectively fulfil your vision in the modern booking system. So we cordially welcome you to our navchr online to book any kind of tickets for travelling. All the travelling agencies also are asked to check our website. Comparing to others you can come to us for booking airlines tickets and we guarantee you to pay the services in comparable. The Convenience charges in our navchr online website is very lowest category (Incl GST). Your satisfied is our mainly moto, your happiness and your happy journey is our only responsibility. Our all team all time help to you and all persons at a time for any airlines journey related problem. We solved Airlines related any journey problem in a few minutes. We make a Hartley relationship to our all defence persons and all others persons for every time.
                            </p>
                            <p>
                                <i>Thanking you and Jai Hind.</i>
                            </p>

                            <p>&#160;</p>
                            <p>
                                <b className='h4'>Vikram Jeet Singh,</b> SM -Indian Army is not a profession but a way of life. Although Indian Army has acquired its customs and traditions from British Army but it also has a very specified regimental ethos derived from the rich history of our great nation. The biggest strength of Indian Army is its brave and gallant soldiers who have proved their metal for long time. Honesty, determination, loyalty, innocence, dedication and perseverance are some of the important traits of an Indian Army soldier. Our soldiers dedicate their whole life for the security and integrity of our nation. They have proved themselves in many wars and internal security situations in our country. We salute the spirit of an Indian Army soldier and we always salute this great nation.
                            </p>
                            <p>&#160;</p>
                            <p>
                                <b className='h4'>
                                    SK Swain -
                                </b> There is nothing worse to pay more while having low fares for the dream you committed. Navchr.com @#overwhelming the dreams come true to fly with clouds with blue scenery. This is the best sights to book yours tickets with affordable price. The site will be the growing and precise website of flyers with a wide range of booking and also hosts a standard booking platform discussion forum. Rousing more to further travel is the way you can ………………………………………………….!
                            </p>


                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
