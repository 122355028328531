import { Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { differenceInMinutes, format } from 'date-fns';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API_BASE_LINK } from '../API_BASE_PATH';
import Flight_Search_Section from '../Slices/Flight_Search_Section';
import FloatingFlightDetails from '../Slices/FloatingFlightDetails';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ArrowRightAlt, ExpandLess, ExpandMore, FlightLand, FlightTakeoff, SyncAlt } from '@material-ui/icons';
import { ArrowBackIosNew, SyncAltSharp } from '@mui/icons-material';
import { DDMMM, DDMMMYYYY, DDMMMYYYYHHMM, HHMM } from '../Utiils/DateForMate';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const FlightSearchPage = () => {
    const urlParameter = useParams();
    const [IsSearchFormOpen, setIsSearchFormOpen] = useState(false);
    const [LoadMoreOneWay, setLoadMoreOneWay] = useState(5);
    const [LoadMore, setLoadMore] = useState(5);
    const [isSelect, setisSelect] = useState({ 'one': -1, 'return': -1 });
    const [SEARCH_STATUS, setSEARCH_STATUS] = useState('Flight searching...');
    const [ALL_ONE_WAY_FLIGHT_DATA, setALL_ONE_WAY_FLIGHT_DATA] = useState([]);
    const [ALL_ROUND_TRIP_FLIGHT_DATA, setALL_ROUND_TRIP_FLIGHT_DATA] = useState([]);
    const [TOTAL_FLIGHT_COUNT, setTOTAL_FLIGHT_COUNT] = useState({ one_way: '', round_trip: '' });
    const [TraceId, setTraceId] = useState('');

    const [GetPayloadFromUrlParams, setGetPayloadFromUrlParams] = useState(null);
    useEffect(() => {
        setGetPayloadFromUrlParams(JSON.parse(window.atob(urlParameter?.id)));
        // console.log(JSON.parse(window.atob(urlParameter?.id)));

    }, [urlParameter?.id]);


    const flight_ready_to_serach = (data) => {
        setSEARCH_STATUS('Flight searching...');
        let request_id = crypto.randomUUID();
        sessionStorage.setItem('search_request_id', request_id)
        axios.post(`${API_BASE_LINK}flight/search`, {
            "adultCount": data?.AdultCount,
            "childCount": data?.ChildCount,
            "infantCount": data?.InfantCount,
            "cabinClass": data?.JourneyType,
            "origin": data?.Origin?.airport_code,
            "destination": data?.Destination?.airport_code,
            "departure": data?.PreferredDepartureDate,
            "return": data.JourneyType === 2 ? data?.PreferredArrivalDate : null,
            "personType": data?.personType?.type_name,
            "isDefence": data?.personType?.type === 'defence' && true,
            "sessionId": request_id,
        }).then((response) => {
            if (data.JourneyType === 1) {
                // console.log('traceId', response?.data?.traceId, response);
                setTraceId(response?.data?.traceId);
                setALL_ROUND_TRIP_FLIGHT_DATA([]);
                setALL_ONE_WAY_FLIGHT_DATA(response?.data?.results[0]);
                setTOTAL_FLIGHT_COUNT({ ...TOTAL_FLIGHT_COUNT, one_way: response?.data?.results[0]?.length });
                setSEARCH_STATUS('')
            } else if (data.JourneyType === 2) {
                // if (response?.data?.results.length > 1) {
                console.log('traceId', response?.data?.traceId, response);
                setTraceId(response?.data?.traceId);
                setALL_ONE_WAY_FLIGHT_DATA(response?.data?.results[0]);

                response?.data?.results[1] && setALL_ROUND_TRIP_FLIGHT_DATA(response?.data?.results[1]);

                setTOTAL_FLIGHT_COUNT({ one_way: response?.data?.results[0]?.length, round_trip: response?.data?.results[1]?.length });
                setSEARCH_STATUS('')
                // } else {
                //     setSEARCH_STATUS('Flight not available on ' + data?.PreferredArrivalDate)
                // }
            } else {
                alert('Try after some time. If some urgent, please contact us');
            }
        }).catch((error) => {
            console.log(error);
            setTOTAL_FLIGHT_COUNT(0);
            // setSEARCH_STATUS(error.message);
            // setSEARCH_STATUS(error?.response?.data?.errorDetail?.ErrorMessage + ' - Error code: ' + error?.response?.data?.errorDetail?.ErrorCode)
            setSEARCH_STATUS('error')

        });

    }

    useEffect(() => {
        // console.log('GetPayloadFromUrlParams---', GetPayloadFromUrlParams);
        if (GetPayloadFromUrlParams !== null) {
            flight_ready_to_serach(GetPayloadFromUrlParams);
        }

    }, [GetPayloadFromUrlParams]);






    const FinalSelectedFlights = (data) => {
        let build_data = {
            "trace_id": TraceId,
            "one_way_flight_details": ALL_ONE_WAY_FLIGHT_DATA[isSelect.one],
            "return_flight_details": ALL_ROUND_TRIP_FLIGHT_DATA[isSelect.return],
        }

        // let payload_for_create_booking = {
        //     "trace_id": TraceId,
        //     "flight_index": build_data?.one_way_flight_details?.ResultIndex,
        //     "is_ilcc": build_data?.one_way_flight_details?.IsLCC,
        //     "return_flight_index": build_data?.return_flight_details?.ResultIndex,
        //     "is_olcc": build_data?.return_flight_details?.IsLCC,
        //     "from": build_data?.one_way_flight_details?.Segments[0]?.[0]?.Origin?.Airport?.AirportCode,
        //     "to": build_data?.return_flight_details?.Segments[0]?.[0]?.Origin?.Airport?.AirportCode,
        //     "journey_date": moment.utc(build_data?.one_way_flight_details?.Segments[0]?.[0]?.Destination?.ArrTime).format("YYYY-MM-DD"),
        //     "return_date": moment.utc(build_data?.return_flight_details?.Segments[0]?.[0]?.Destination?.ArrTime).format("YYYY-MM-DD"),
        // }
    }

    const showStartingCancelFee = (data) => {
        const cancellationRule = data?.[0].find((rule) => rule?.Type === 'Cancellation');
        const details = cancellationRule?.Details;
        return details
    }

    return (
        <main className='FLIGHT_SEARCH_PAGE'>

            <div className={`flight_search_holder ${IsSearchFormOpen && 'active'}`}>
                <div className="container">
                    <Flight_Search_Section sendLastSearch={GetPayloadFromUrlParams} />
                </div>
            </div>



            <div className="toggol_search_flight_section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <button
                                className="btn togBtn"
                                onClick={() => setIsSearchFormOpen(!IsSearchFormOpen)}>
                                {IsSearchFormOpen === true ? <ExpandLess /> : <ExpandMore />}
                            </button>
                        </div>
                        <div className="col-auto  mx-auto">
                            <div
                                className="plate d-flex align-items-center"
                                onClick={() => setIsSearchFormOpen(!IsSearchFormOpen)}
                            >

                                {GetPayloadFromUrlParams?.Origin?.airport_code}
                                <span >
                                    &#160;  {GetPayloadFromUrlParams?.JourneyType === 1 ? <ArrowRightAlt /> : <SyncAltSharp />}   &#160;
                                </span>
                                {GetPayloadFromUrlParams?.Destination?.airport_code}
                                <div className="bigDot">

                                </div>

                                {moment.utc(GetPayloadFromUrlParams?.PreferredDepartureDate).format('DD MMM')}

                                {GetPayloadFromUrlParams?.JourneyType === 2 &&
                                    <>
                                        <div className="bigDash"></div>
                                        {moment.utc(GetPayloadFromUrlParams?.PreferredArrivalDate).format('DD MMM')}
                                    </>
                                }

                                <div className="bigDot">

                                </div>

                                {GetPayloadFromUrlParams?.AdultCount + GetPayloadFromUrlParams?.ChildCount + GetPayloadFromUrlParams?.InfantCount}
                                &#160; Travellers
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="AllFlightResultSection  ">
                <div className={`${GetPayloadFromUrlParams?.JourneyType === 1 ? 'container pt-3' : 'container-fluid pt-3'}`} >
                    <div className="row">
                        <div className={`${GetPayloadFromUrlParams?.JourneyType === 1 ? 'col-md-10 mx-auto' : 'col-6 mx-auto'}`}>
                            <div className="holderBox">
                                <div className="layer_one   ">
                                    <h4> {GetPayloadFromUrlParams?.Origin?.airport_code} <FlightTakeoff /> {GetPayloadFromUrlParams?.Destination?.airport_code} | {moment.utc(GetPayloadFromUrlParams?.PreferredDepartureDate).format("DD MMM, YYYY")}  </h4>
                                </div>
                                {SEARCH_STATUS !== '' ?
                                    <div className="text-center pt-5">
                                        {SEARCH_STATUS === "Flight searching..." &&
                                            <div className="text-center">
                                                <CircularProgress />
                                            </div>
                                        }
                                        {SEARCH_STATUS !== 'error' && <h4> {SEARCH_STATUS} </h4>}
                                        {SEARCH_STATUS == 'error' &&
                                            <>
                                                <h4>We have extremely sorry !</h4>
                                                <p>"We suggest you please modify your search and try again"</p>
                                                <Link className='btn cust_btn_one' to='/'>Modify Search</Link>
                                            </>

                                        }
                                    </div>
                                    :
                                    <div className="layer_three">
                                        {/* {ALL_ONE_WAY_FLIGHT_DATA?.sort((a, b) => { return a.Segments[0]?.[0]?.Origin?.DepTime.localeCompare(b.Segments[0]?.[0]?.Origin?.DepTime) }).map((data, index) => ( */}
                                        {ALL_ONE_WAY_FLIGHT_DATA?.map((data, index) => (
                                            <div key={index}>
                                                {/* {data?.Segments.map((FINAL_DATA, FINAL_INDEX) => ( */}
                                                <>
                                                    {/* <p>{JSON.stringify(data?.MiniFareRules)}</p> */}
                                                    {/* <h1>{data?.ResultIndex}</h1> */}
                                                    <div className={`d-md-flex flight_card ${isSelect.one === index ? 'Selected' : 'Select'}`} key={data.ResultIndex + index}>
                                                        <div className="box leftBox">
                                                            <div className="inside d-flex d-md-block">
                                                                <figure className={`d-inline-flex ${data?.Segments[0]?.[0]?.Airline?.AirlineName}`}>
                                                                    <img src={`/img/AirlineLogo/${data?.Segments[0]?.[0]?.Airline?.AirlineName}.png`} alt={data?.Segments[0]?.Airline?.AirlineName} />
                                                                </figure>
                                                                <div className="ps-3 ps-md-0 text-md-center text-start w-100">
                                                                    <div className="tire1 mb-md-2">
                                                                        <b>{data?.Segments[0]?.[0]?.Airline?.AirlineName}</b>
                                                                        <span>{data?.Segments[0]?.[0]?.Airline?.AirlineCode} - {data?.Segments[0]?.[0]?.Airline?.FlightNumber}</span>
                                                                    </div>
                                                                    <div className="tire2">
                                                                        <b>Luggage Details</b>
                                                                        <span> {data?.Segments[0]?.[0]?.CabinBaggage}/{data?.Segments[0]?.[0]?.Baggage}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="box middleBox d-flex flex-column justify-content-around">
                                                            {/* <div className="roundBox1"></div>
                                                        <div className="roundBox2"></div> */}
                                                            <div className="inside">
                                                                <div className="line1 d-md-flex justify-content-between">
                                                                    <div>
                                                                        <img src="/img/ico10.svg" alt="" />
                                                                        <span>Seats</span>
                                                                        <b>{data?.Segments[0]?.[0]?.NoOfSeatAvailable} left</b>
                                                                    </div>
                                                                    <div>
                                                                        <img src="/img/ico11.svg" alt="" />
                                                                        <span>Date</span>
                                                                        <b>{DDMMM(data?.Segments[0]?.[0]?.Origin?.DepTime)}  - {DDMMMYYYY(data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.ArrTime)}  </b>
                                                                    </div>
                                                                </div>
                                                                <div className="line2 d-flex align-items-center justify-content-between ">
                                                                    <div className="pos">
                                                                        <b>{HHMM(data?.Segments[0]?.[0]?.Origin?.DepTime)}</b>
                                                                        <div>
                                                                            <div className="carcle"><em></em></div>
                                                                        </div>
                                                                        <span>{data?.Segments[0]?.[0]?.Origin?.Airport?.AirportCode}</span>
                                                                    </div>
                                                                    <div className="time">
                                                                        {data?.Segments[0]?.length > 1 ?
                                                                            <span>{Math.floor((data?.Segments[0]?.[0]?.Duration + data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Duration) / 60)}h {(data?.Segments[0]?.[0]?.Duration + data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Duration) % 60}m</span>
                                                                            :
                                                                            <span>{Math.floor(data?.Segments[0]?.[0]?.Duration / 60)}h {data?.Segments[0]?.[0]?.Duration % 60}m</span>
                                                                        }
                                                                        <div className="py-1">
                                                                            <img src="/img/ico12.svg" alt="" />
                                                                        </div>
                                                                        <small> {data?.Segments[0]?.length > 1 ? <span>1 Stop via {data?.Segments[0]?.[0]?.Destination?.Airport?.CityName}</span> : <span>Non-Stop</span>}</small>

                                                                    </div>
                                                                    <div className="pos">
                                                                        <b>{HHMM(data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.ArrTime)}</b>
                                                                        <div>
                                                                            <div className="carcle two"><em></em></div>
                                                                        </div>
                                                                        <span>{data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.Airport?.AirportCode}</span>
                                                                        {/* <span>{data?.Segments[0]?.[-0]?.Destination?.Airport?.AirportCode}</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="line3">
                                                                    {data?.TicketAdvisory && <><b>Airline Remark:</b>{data?.TicketAdvisory}</>}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="box righttBox">
                                                            <div className="d-flex d-md-block flex-wrap inside justify-content-between">
                                                                <div className="line1 mb-2 w-100">
                                                                    {data?.IsRefundable === false ? <span> Non Refundable</span> : <span style={{ backgroundColor: "#05cb05" }}>Refundable</span>}
                                                                </div>

                                                                {data?.IsRefundable === true && <div className="line2  w-100"> <p><small>Cancellation Fee <br /> Starting:</small> <b>{showStartingCancelFee(data?.MiniFareRules)}</b> </p> </div>}

                                                                <div className="line3">
                                                                    <span>₹{Math.round(data?.Fare?.PublishedFare).toLocaleString()}</span>
                                                                </div>
                                                                <div className="line4">
                                                                    <button disabled={isSelect.one === index ? true : false} className={`btn cust_btn_one ${isSelect.one === index ? 'Selected' : 'Select'}`} onClick={() => (setisSelect({ ...isSelect, one: index }), FinalSelectedFlights())}>
                                                                        {isSelect.one === index ? 'Selected' : 'Select'}
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            </div>
                                        ))}


                                    </div>
                                }

                            </div>

                        </div>


                        {GetPayloadFromUrlParams?.JourneyType === 2 &&

                            <div className={`${GetPayloadFromUrlParams?.JourneyType === 1 ? 'col-md-10 mx-auto' : 'col-6 mx-auto'}`}>
                                <div className="holderBox">
                                    <div className="layer_one  ">
                                        <h4> {GetPayloadFromUrlParams?.Destination?.airport_code} <FlightLand /> {GetPayloadFromUrlParams?.Origin?.airport_code} | {moment.utc(GetPayloadFromUrlParams?.PreferredArrivalDate).format("DD MMM, YYYY")}  </h4>
                                    </div>


                                    {SEARCH_STATUS !== '' ?
                                        <div className="text-center pt-5">
                                            {SEARCH_STATUS === "Flight searching..." &&
                                                <div className="text-center">
                                                    <CircularProgress />
                                                </div>
                                            }

                                            <h4> {SEARCH_STATUS}</h4>
                                        </div>

                                        :

                                        <div className="layer_three">

                                            {ALL_ROUND_TRIP_FLIGHT_DATA?.length > 0 ?
                                                <div>
                                                    {ALL_ROUND_TRIP_FLIGHT_DATA?.map((data, index) => (
                                                        <div key={index} className={`${isSelect.one >= 0 ? 'apply' : 'notApply'}   ${Math.round((new Date(data?.Segments[0]?.[0]?.Origin?.DepTime) - new Date(ALL_ONE_WAY_FLIGHT_DATA[isSelect.one]?.Segments[0]?.[ALL_ONE_WAY_FLIGHT_DATA[isSelect.one]?.Segments[0]?.length - 1]?.Destination?.ArrTime)) / (1000 * 60 * 60)) >= 2 ? 'visible' : 'hide'}   `}>
                                                            <div className={`d-md-flex flight_card ${isSelect.return === index ? 'Selected' : 'Select'}`} key={data.ResultIndex + index}>
                                                                <div className="box leftBox">
                                                                    <div className="inside d-flex d-md-block">

                                                                        <figure className={`d-inline-flex ${data?.Segments[0]?.[0]?.Airline?.AirlineName}`}>
                                                                            <img src={`/img/AirlineLogo/${data?.Segments[0]?.[0]?.Airline?.AirlineName}.png`} alt={data?.Segments[0]?.Airline?.AirlineName} />
                                                                        </figure>

                                                                        <div className="ps-3 ps-md-0 text-md-center text-start w-100">
                                                                            <div className="tire1  mb-md-2">
                                                                                <b>{data?.Segments[0]?.[0]?.Airline?.AirlineName}</b>
                                                                                <span>{data?.Segments[0]?.[0]?.Airline?.AirlineCode} - {data?.Segments[0]?.[0]?.Airline?.FlightNumber}</span>
                                                                            </div>
                                                                            <div className="tire2">
                                                                                <b>Luggage Details</b>
                                                                                {data?.Segments[0]?.[0]?.CabinBaggage}/{data?.Segments[0]?.[0]?.Baggage}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="box middleBox d-flex flex-column justify-content-around">
                                                                    {/* <div className="roundBox1"></div>
                                                                    <div className="roundBox2"></div> */}
                                                                    <div className="inside">
                                                                        <div className="line1 d-md-flex justify-content-between">
                                                                            <div>
                                                                                <img src="/img/ico10.svg" alt="" />
                                                                                <span>Seats</span>
                                                                                <b>{data?.Segments[0]?.[0]?.NoOfSeatAvailable} left</b>
                                                                            </div>
                                                                            <div>
                                                                                <img src="/img/ico11.svg" alt="" />
                                                                                <span>Date</span>
                                                                                <b> {moment.utc(data?.Segments[0]?.[0]?.Origin?.DepTime).format('DD MMM')} - {moment.utc(data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.ArrTime).format("DD MMM, YYYY")}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div className="line2 d-flex align-items-center justify-content-between ">
                                                                            <div className="pos">
                                                                                <b>{HHMM(data?.Segments[0]?.[0]?.Origin?.DepTime)}</b>
                                                                                <div>
                                                                                    <div className="carcle"><em></em></div>
                                                                                </div>
                                                                                <span>{data?.Segments[0]?.[0]?.Origin?.Airport?.AirportCode}</span>
                                                                            </div>
                                                                            <div className="time">
                                                                                {/* {data?.Segments[0]?.length > 1 ?
                                                                            <span>{Math.floor((data?.Segments[0]?.[0]?.Duration + data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Duration + data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.GroundTime) / 60)}h {(data?.Segments[0]?.[0]?.Duration + data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Duration) % 60}m</span>
                                                                            :
                                                                            <span>{Math.floor(data?.Segments[0]?.[0]?.Duration / 60)}h {data?.Segments[0]?.[0]?.Duration % 60}m</span>
                                                                        } */}
                                                                                <span>
                                                                                    {Math.floor(differenceInMinutes(new Date(data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.ArrTime), new Date(data?.Segments[0]?.[0]?.Origin?.DepTime)) / 60)}h &#160;
                                                                                    {Math.floor(differenceInMinutes(new Date(data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.ArrTime), new Date(data?.Segments[0]?.[0]?.Origin?.DepTime)) % 60)}m
                                                                                </span>

                                                                                <div className="py-1">
                                                                                    <img src="/img/ico12.svg" alt="" />
                                                                                </div>
                                                                                <small> {data?.Segments[0]?.length > 1 ? <span>1 Stop via {data?.Segments[0]?.[0]?.Destination?.Airport?.CityName}</span> : <span>Non-Stop</span>}</small>

                                                                            </div>
                                                                            <div className="pos">
                                                                                <b>{HHMM(data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.ArrTime)}</b>

                                                                                <div>
                                                                                    <div className="carcle two"><em></em></div>
                                                                                </div>
                                                                                <span>{data?.Segments[0]?.[data?.Segments[0]?.length - 1]?.Destination?.Airport?.AirportCode}</span>
                                                                                {/* <span>{data?.Segments[0]?.[-0]?.Destination?.Airport?.AirportCode}</span> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="line3">
                                                                            {data?.TicketAdvisory && <><b>Airline Remark:</b>{data?.TicketAdvisory}</>}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="box righttBox">
                                                                    <div className="d-flex d-md-block flex-wrap inside justify-content-between">
                                                                        <div className="line1 mb-2 w-100">
                                                                            {data?.IsRefundable === false ? <span> Non Refundable</span> : <span style={{ backgroundColor: "#05cb05" }}>Refundable</span>}
                                                                        </div>

                                                                        {data?.IsRefundable === true && <div className="line2   w-100"> <p><small>Cancellation Fee <br /> Starting:</small> <b>{showStartingCancelFee(data?.MiniFareRules)}</b> </p> </div>}

                                                                        <div className="line3">
                                                                            <span>₹{Math.round(data?.Fare?.PublishedFare).toLocaleString()}</span>
                                                                        </div>
                                                                        <div className="line4">
                                                                            <button disabled={isSelect.return === index ? true : false} className={`btn cust_btn_one ${isSelect.return === index ? 'Selected' : 'Select'}`} onClick={() => (setisSelect({ ...isSelect, return: index }), FinalSelectedFlights())}>
                                                                                {isSelect.return === index ? 'Selected' : 'Select'}
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </div> : <>
                                                    <div className="">
                                                        <ThemeProvider theme={darkTheme}>
                                                            <Alert severity="warning">No return flight available on {GetPayloadFromUrlParams?.PreferredArrivalDate}</Alert>
                                                        </ThemeProvider>
                                                    </div>
                                                </>}

                                        </div>
                                    }

                                </div>
                            </div>



                        }


                    </div>

                </div>
            </div>


            {
                SEARCH_STATUS === '' && isSelect.one !== -1 &&
                <div className="Floating_flight_details_wraper">
                    <FloatingFlightDetails trip_type={GetPayloadFromUrlParams?.JourneyType} selected_flight_details={{ 'one_way': ALL_ONE_WAY_FLIGHT_DATA[isSelect.one], 'round_trip': ALL_ROUND_TRIP_FLIGHT_DATA[isSelect.return], 'selected_id': isSelect, 'trace_id': TraceId }} />
                </div>
            }
        </main >
    );
}

export default FlightSearchPage;
